<template>
  <div class="pageContol listWrap AnnouncementPush">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">消息管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">公告推送</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="标题名称" class="searchboxItem ci-full">
              <span class="itemLabel">标题名称:</span>
              <el-input
                size="small"
                v-model="noticeTitle"
                type="text"
                placeholder="请输入标题名称"
                clearable
              />
            </div>
            <div title="发布时间" class="searchboxItem ci-full">
              <span class="itemLabel">发布时间:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="startTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div title="角色名称" class="searchboxItem ci-full">
              <span class="itemLabel">角色名称:</span>
              <el-select v-model="roleId" placeholder="请选择" size="small" clearable>
                <el-option
                  v-for="item in realRoleName"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-top:15px">
            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel">行政区划:</span>
              <el-cascader
                :options="areatreeList"
                :props="propsarea"
                clearable
                filterable
                size="small"
                v-model="areaId"
                style="width: 100%;"
              ></el-cascader>
            </div>
            <div title="公告类型" class="searchboxItem ci-full">
              <span class="itemLabel">公告类型:</span>
              <el-select v-model="noticeType" placeholder="请选择" size="small" clearable>
                <el-option
                  v-for="item in noticeTypeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </div>
            <div title="推荐时间" class="searchboxItem ci-full">
              <span class="itemLabel">推荐时间:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="recommendTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
            <div class="df" style="min-width: 190px;">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button
                type="primary"
                size="small"
                class="bgc-bv"
                round
                @click="confirmDocsDialog(0)"
              >发布公告</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
                width="100px"
              />

              <el-table-column
                label="标题名称"
                align="left"
                show-overflow-tooltip
                fixed
                prop="noticeTitle"
                minWidth="200px"
              />
              <el-table-column
                label="公告类型"
                align="left"
                show-overflow-tooltip
                prop="noticeType"
                minWidth="100px"
              >
                <template slot-scope="scope">
                  {{
                  $setDictionary('NOTICETYPE',scope.row.noticeType || '--')
                  }}
                </template>
              </el-table-column>
              <el-table-column
                label="发布者"
                align="left"
                show-overflow-tooltip
                prop="fullname"
                minWidth="100px"
              />
              <el-table-column
                label="发布时间"
                align="left"
                show-overflow-tooltip
                prop="createTime"
                width="200px"
              >
                <template slot-scope="scope">{{scope.row.createTime|moment}}</template>
              </el-table-column>
              <el-table-column
                label="角色名称"
                align="left"
                show-overflow-tooltip
                prop="roleNames"
                minWidth="200px"
              />

              <el-table-column
                label="行政区域"
                align="left"
                show-overflow-tooltip
                prop="areaNames"
                minWidth="100px"
              />
              <el-table-column label="推荐时间" align="left" minWidth="170px">
                <template slot-scope="scope">
                  {{ scope.row.recommendStartDate }} - {{ scope.row.recommendEndDate }}
                </template>
              </el-table-column>
              <el-table-column label="操作" align="center" minWidth="120px" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="confirmDocsDialog(scope.row.noticeId)"
                  >修改</el-button>
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="handleDel(scope.row.noticeId)"
                  >删除</el-button>
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    :disabled="scope.row.disabled"
                    @click="toTop(scope.row.noticeId)"
                  >置顶</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import moment from "moment";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "AnnouncementPush",
  components: {
    Empty,
    PageNum
  },
  data() {
    return {
      areatreeList: [],
      noticeTitle: "", //标题名称
      userTime: "", //发布时间
      realRoleName: "", //角色名称
      realRoleNameList: [],
      areaId: "", //行政区域
      roleId: "",
      startTime: "", //发布时间
      recommendTime: "", // 推荐时间
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true
      },
      noticeTypeList: [], // 公告类型
      noticeType:''
    };
  },
  mixins: [List],
  created() {
    this.getnoticeify();
  },
  computed: {},

  mounted() {
    this.getareatree();
    this.getrealRoleName();
    this.getTableHeight();
  },
  watch: {
    tableData: function(val) {
      if (this.pageNum == "1" && this.tableData.length > 0) {
        this.tableData[0]["disabled"] = true;
      }
    },
    $route: {
      handler: function(val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        }
        if (oldVal.query.id == 0) {
          (this.noticeTitle = ""),
            (this.startTime = ""),
            (this.roleId = ""),
            (this.areaId = ""),
            (this.pageNum = 1),
            this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true
    }
  },
  methods: {
    getnoticeify() {
      const list = this.$setDictionary("NOTICETYPE", "list") || [];
      this.noticeTypeList = [];
      for (let key in list) {
        this.noticeTypeList.push({ id: key, name: list[key] });
      }
    },
    // 删除
    handleDel(noticeId) {
      this.$confirm("您确定要删除这条公告吗？", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$post("/notice/deleteNotice", { noticeId }).then(res => {
            if (res.status == "0") {
              this.$message({
                type: "success",
                message: "删除成功!"
              });
              this.getData(-1);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then(ret => {
        this.areatreeList = ret.data;
      });
    },
    //获取角色列表
    // /sys/per/role/list
    getrealRoleName() {
      this.$post("/sys/per/role/list").then(ret => {
        this.realRoleName = ret.data;
      });
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 3.25) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
      };
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.roleId) {
        params.roleId = this.roleId;
      }
      if (this.startTime) {
        params.startDate = this.startTime[0];
        params.endDate = this.startTime[1];
      }
      if (this.noticeType) {
        params.noticeType = this.noticeType;
      }
      if (this.noticeTitle) {
        params.noticeTitle = this.noticeTitle;
      }
      if (this.recommendTime) {
        params.startRecommendStartDate = this.recommendTime[0];
        params.endRecommendStartDate = this.recommendTime[1];
      }   
      this.doFetch({
        url: "/notice/queryNoticeList",
        params,
        pageNum
      });
    },
    //发布公告
    confirmDocsDialog(id) {
      this.$router.push({
        path: "/web/AnnouncementPushEdit",
        query: {
          id: id
        }
      });
    },
    //修改

    //置顶
    toTop(noticeId) {
      this.$confirm("您确定要置顶这条公告吗？", "置顶", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.$post("/notice/modifyTop", { noticeId }).then(res => {
            if (res.status == "0") {
              this.$message({
                type: "success",
                message: "置顶成功!"
              });
              this.getData(-1);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消置顶"
          });
        });
    }
  },
  beforeRouteLeave: resetKeepAlive
};
</script>

<style lang="less" >
.AnnouncementPush {
  .operationControl {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    align-items: flex-start;
  }
  // .searchboxItem {
  //   display: flex;
  //   flex-direction: row;
  //   align-items: center;
  //   margin-top: 10px;

  // .el-cascader {
  //   height: 32px !important;
  // }
  //   .itemLabel {
  //     width: 150px;
  //     text-align: right;
  //   }
  // }
}
</style>
